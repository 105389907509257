<template>
  <div>
    <div class="content-header cols">
      <div>
        <h2>{{ $tfo('common.webhooks') }}</h2>
      </div>
      <div class="content-header-buttons">
        <el-button
          name="create-btn"
          class="header-button"
          circle
          icon="el-icon-plus"
          size="small"
          :disabled="$hasNoPermission('ffsecurity.add_webhook')"
          @click="$router.push({ path: '/hooks/create/' })"
        />
      </div>
    </div>

    <el-table
      name="hooks-table"
      class="hooks-table table-medium"
      stripe
      :size="$vars.sizes.table || $vars.sizes.common"
      :data="state.items"
      @row-click="rowClick"
    >
      <el-table-column prop="name" :label="$tf('common.name')" width="200">
        <template slot-scope="{ row }">
          <router-link name="item-btn" :to="{ path: getItemLink(row) }">{{ row.name }}</router-link
          ><br />
          {{ row.comment }}
        </template>
      </el-table-column>
      <el-table-column prop="url" :label="$tf('common.url')"></el-table-column>
      <el-table-column prop="id" :label="$tf('common.id')" width="70"></el-table-column>
      <el-table-column prop="active" :label="$tf('common.active')" align="center" width="100">
        <template slot-scope="{ row }">
          <div @click.stop class="text-center">
            <el-checkbox v-model="row.active" @change="(v) => activeChange(v, row)"> </el-checkbox>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'page-hooks',
  computed: {
    state() {
      return this.$store.state.hooks;
    }
  },
  mounted() {
    this.$store.dispatch(this.state.Action.Get);
  },
  methods: {
    rowClick(item) {
      if (this.$store.state.app.key.ctrl) return;
      this.$router.push({ path: this.getItemLink(item) });
    },
    getItemLink(item) {
      return '/hooks/' + encodeURIComponent(item.id) + '/';
    },
    activeChange(v, item) {
      this.$store.dispatch(this.state.Action.Update, { id: item.id, active: v }).catch((e) => {
        item.active = !v;
      });
      return false;
    }
  }
};
</script>
